import React from 'react'
import './style.css'

const SimpleImage = ({ newImageUrl }) => {
    return (
        <>
            <div className="image-box">
                <img src={newImageUrl} alt="" />
            </div>
        </>
    )
}

export default SimpleImage