import React from 'react'
import './style.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Testimonial = () => {

    const TestimonialContent = [
        {
            image: '/images/user2.jpg',
            text: 'LiteLayer is my favorite app right now. It has so many awesome wallpapers, ringtones and notifications For your phone.',
            author: 'Allen W'
        },
        {
            image: '/images/user1.jpg',
            text: 'LiteLayer is my favorite app right now. It has so many awesome wallpapers, ringtones and notifications For your phone.',
            author: 'Thomas J'
        },
        {
            image: '/images/user3.jpg',
            text: 'LiteLayer is my favorite app right now. It has so many awesome wallpapers, ringtones and notifications For your phone.',
            author: 'Julia K'
        },
    ]

    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    return (
        <>
            <div className="testimonial-box-wraper full-page-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="testimonial-text-box">
                                <h1>Tell us Everything</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Slider {...settings}>
                                {TestimonialContent.map((content, index) => (
                                    <div className="testimonial-card-box" key={index}>
                                        <div className="testimonial-card-image">
                                            <img src={content.image} alt="" />
                                        </div>
                                        <p>{content.text}</p>
                                        <h1>‘’{content.author}.</h1>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial