import React from 'react'
import './style.css'
import DownloadButtons from '../DownloadButtons/DownloadButtons'
import MobileSlider from '../MobileSlider/MobileSlider'

const GetWallpaper = () => {
    return (
        <>
            <div className="get-wallpaper-box pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="join-our-community-text">
                                <p>Get Your Excellent Wallpaper</p>
                                <h1>Elevate your imagery game with <span>Litelayers:</span> Power-packed <br /> editing at your!<span>fingertips</span></h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-5-now">
                    <div className="row">
                        <div className="col-md-12">
                            <MobileSlider />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetWallpaper