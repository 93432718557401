import React, { useState } from 'react';
import './style.css';

const sliderImages = [
    {
        image: '/images/wallpaper5.png',
        text: 'Fashion'
    },
    {
        image: '/images/wallpaper7.png',
        text: 'Butterfly'
    },
    {
        image: '/images/wallpaper3.jpg',
        text: 'Girl'
    },
    {
        image: '/images/wallpaper1.jpg',
        text: 'Music'
    },
    {
        image: '/images/wallpaper2.png',
        text: 'Cat'
    },
    {
        image: '/images/wallpaper6.png',
        text: 'Anime'
    },
    {
        image: '/images/wallpaper4.png',
        text: 'Nature'
    }
];

const BestApp = () => {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    return (
        <>
            <div className="best-app-box full-page-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="best-app-text">
                                <h1>The Best App You’ll <span>Ever Need</span></h1>
                                <p>Start with a customizable template or from a blank canvas. However you begin, the end is a design that’s all yours.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sldier-box">
                    <div className="col-md-12">
                        <div className="hover-images-box">
                            {sliderImages.map((item, index) => (
                                <div
                                    key={index}
                                    className={`slider-image ${index === 3 && hoveredIndex === null ? 'hover-img' : ''} ${hoveredIndex === index ? 'hover-img' : ''}`}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <img src={item.image} alt={`Slide ${index + 1}`} />
                                    <h1>{item.text}</h1>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default BestApp