import React from 'react'
import Header from '../components/Header/Header'
import Banner from '../components/HeroSection/Banner'
import JoinOurCommunity from '../components/JoinCommunity/JoinOurCommunity'
import CreateRemoveBg from '../components/RemoveBackground/CreateRemoveBg'
import ExploreApp from '../components/ExploreApp/ExploreApp'
import UltimateCompain from '../components/Ultimate/UltimateCompain'
import Footer from '../components/Footer/Footer'
import BestApp from '../components/BestApp/BestApp'
import Testimonial from '../components/Testimonial/Testimonial'
import GetWallpaper from '../components/GetWallpaper/GetWallpaper'

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <JoinOurCommunity />
      <GetWallpaper/>
      <CreateRemoveBg />
      <ExploreApp />
      <BestApp />
      <UltimateCompain />
      <Testimonial/>
      <Footer/>
    </>
  )
}

export default Home