import React, { useContext, useState, useMemo, useCallback } from 'react';
import './style.css';
import HdImageSwiper from '../HDimageswiper/HdImageSwiper';
import ChangeBackground from '../ChangeBackground/ChangeBackground';
import MyContext from '../../contaxt/contaxt';
import SimpleImage from '../simpleImage/SimpleImage';
import RemoveBgImageSwiper from '../RemoveBgImageSwiper/RemoveBgImageSwiper';

const tabContents = [
    {
        title: 'Image HD',
        gifSrc: '/images/enhancer.gif',
        text: 'Unlock a world of stunning visuals with our collection of high-definition images. From breathtaking landscapes to detailed close-ups, our library offers a variety of HD images perfect for any project. Enjoy crystal-clear, high-quality pictures that bring your ideas to life!',
        videoSrc: <HdImageSwiper imageUrl="/images/user1.jpg" />,
        iconClass: "fa-solid fa-image",
        changeBgBtn: false,
    },
    {
        title: 'Change Background',
        gifSrc: '/images/chngrbg.gif',
        text: 'Transform the scene with a new backdrop by effortlessly changing the background.',
        videoSrc: <ChangeBackground />,
        iconClass: "fa-solid fa-clone",
        changeBgBtn: [true, "Change"],
    },
    {
        title: 'Edit Your Image',
        gifSrc: '/images/editor.gif',
        text: 'Edit your images with powerful tools.',
        videoSrc: <SimpleImage newImageUrl="/images/edit-image.png" />,
        iconClass: "fa-solid fa-images",
        changeBgBtn: false,
    },
    {
        title: 'Make Collage',
        gifSrc: '/images/collage.gif',
        text: 'Create stunning collages easily.',
        videoSrc: "/images/collage.mp4",
        iconClass: "fa-solid fa-table-cells-large",
        changeBgBtn: false,
    },
    {
        title: 'Remove Background',
        gifSrc: '/images/remove-bg.gif',
        text: 'Remove the background from images with ease.',
        videoSrc: <RemoveBgImageSwiper fixedImageUrl="/images/people-org.png" removeBgImageUrl="/images/people-remove-bg.png" bgImageUrl="/images/sunset.jpg" bgClass={"remove-bg-with-sites"}/>,
        iconClass: "fa-solid fa-table-cells",
        changeBgBtn: false,
    },
    {
        title: 'Watermark Remover',
        gifSrc: '/images/watermark.gif',
        text: 'Effortlessly remove watermarks from images.',
        videoSrc: <HdImageSwiper imageUrl="/images/bg5.jpg" secondImageUrl="/images/watermark.png" />,
        // videoSrc: <RemoveBgImageSwiper fixedImageUrl="/images/watermark-bg.png" removeBgImageUrl="/images/simple-bg.png" bgImageUrl="/images/watermark-bg.png" bgClass={"couple-bg"} />,
        iconClass: "fa-solid fa-paint-roller",
        changeBgBtn: false,
    }
];

const TabButton = React.memo(({ index, isActive, onClick, iconClass, title }) => (
    <div
        className={`tab-btn-box ${isActive ? 'active-tab' : ''}`}
        onClick={() => onClick(index)}
    >
        <h1 className='explore-icon'><i className={iconClass}></i></h1>
        <h3>{title}</h3>
    </div>
));

const ExploreApp = () => {
    const { changeBackground, RemoveBgTabsFunc } = useContext(MyContext);
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = useCallback((index) => {
        setActiveTab(index);
    }, []);

    const currentTabContent = useMemo(() => tabContents[activeTab], [activeTab]);

    return (
        <div className="explore-app-box full-page-sec">
            <div className="container height-35-square">
                <div className="row">
                    <div className="col-md-12">
                        <div className="explore-app-text">
                            <h1>Explore the <span>LiteLayers</span> app</h1>
                            <p>Get Creative with our <span>AI photo editing tools</span></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="tabs-buttons-box">
                            {tabContents.map((tab, index) => (
                                <TabButton
                                    key={index}
                                    index={index}
                                    isActive={index === activeTab}
                                    onClick={handleTabClick}
                                    iconClass={tab.iconClass}
                                    title={tab.title}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-back-box">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 ps-0-now">
                            <div className="explore-app-image h-100">
                                {typeof currentTabContent.videoSrc === 'string' ? (
                                    <video
                                        src={currentTabContent.videoSrc}
                                        alt={currentTabContent.title}
                                        autoPlay
                                        muted
                                        loop
                                        preload="auto"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                ) : (
                                    <div className='h-100'>
                                        {currentTabContent.videoSrc}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="icon-text">
                                <div className="text-heading">
                                    <h1><span>{currentTabContent.title}</span> <i className={currentTabContent.iconClass}></i></h1>
                                </div>
                                <p>{currentTabContent.text}</p>
                                {currentTabContent.changeBgBtn && (
                                    <div className="change-bt-btn">
                                        <a
                                            href='#'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                currentTabContent.changeBgBtn[1] === "Change"
                                                    ? changeBackground(e)
                                                    : RemoveBgTabsFunc(e);
                                            }}
                                        >
                                            {currentTabContent.changeBgBtn[1]} Background
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExploreApp;