import React, { useContext } from 'react'
import './style.css'
import MyContext from '../../contaxt/contaxt'

const ChangeBackground = () => {

    const { bgChanges, currIndex } = useContext(MyContext)

    return (
        <>
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div className="col-md-12 space-control-class">
                        <div className="create-bg-image-tabs">
                            <img src={bgChanges[currIndex]} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangeBackground