import React, { useRef, useState, useEffect } from 'react';
import './style.css';

const RemoveBgImageSwiper = ({ fixedImageUrl, removeBgImageUrl, bgClass }) => {
    const sliderRef = useRef(null);
    const swipeImageRef = useRef(null);
    const dividerRef = useRef(null);
    const [bgImage, setBgImage] = useState(bgClass)

    useEffect(() => {
        if (!sliderRef.current) return;

        const slider = sliderRef.current;
        const swipeImage = swipeImageRef.current;
        const divider = dividerRef.current;

        const handleDragStart = (e) => {
            e.preventDefault();
            divider.classList.add('draggable');

            const startX = e.touches ? e.touches[0].pageX : e.pageX;
            const containerOffset = slider.getBoundingClientRect().left;
            const containerWidth = slider.offsetWidth;
            const dragWidth = divider.offsetWidth;
            const posX = divider.offsetLeft + dragWidth - startX;
            const minLeft = containerOffset + 10;
            const maxLeft = containerOffset + containerWidth - dragWidth - 10;

            const handleDragMove = (moveEvent) => {
                moveEvent.preventDefault();

                const moveX = moveEvent.touches ? moveEvent.touches[0].pageX : moveEvent.pageX;
                let leftValue = moveX + posX - dragWidth;

                if (leftValue < minLeft) {
                    leftValue = minLeft;
                } else if (leftValue > maxLeft) {
                    leftValue = maxLeft;
                }

                const widthValue = ((leftValue + dragWidth / 2 - containerOffset) * 100) / containerWidth + '%';

                divider.style.left = widthValue;
                swipeImage.style.width = widthValue;
            };

            const handleDragEnd = () => {
                divider.classList.remove('draggable');

                document.removeEventListener('mousemove', handleDragMove);
                document.removeEventListener('touchmove', handleDragMove);
                document.removeEventListener('mouseup', handleDragEnd);
                document.removeEventListener('touchend', handleDragEnd);
                document.removeEventListener('touchcancel', handleDragEnd);
            };

            document.addEventListener('mousemove', handleDragMove);
            document.addEventListener('touchmove', handleDragMove);
            document.addEventListener('mouseup', handleDragEnd);
            document.addEventListener('touchend', handleDragEnd);
            document.addEventListener('touchcancel', handleDragEnd);
        };

        divider.addEventListener('mousedown', handleDragStart);
        divider.addEventListener('touchstart', handleDragStart);

        return () => {
            divider.removeEventListener('mousedown', handleDragStart);
            divider.removeEventListener('touchstart', handleDragStart);
        };
    }, [fixedImageUrl, removeBgImageUrl]);

    return (
        <div className={`comparison-slider-wrapper ${bgImage}`} ref={sliderRef}>
            <img src={fixedImageUrl} alt="Fixed" className="fixed-image" />
            <div className="swipe-container" ref={swipeImageRef}>
                <img src={removeBgImageUrl} alt="Removed Background" className="swipe-image" />
            </div>
            <div className="divider" ref={dividerRef}></div>
        </div>
    );
};

export default RemoveBgImageSwiper;
