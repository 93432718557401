import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer className='footer-background'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-logo">
                                <div className="footer-logo-box">
                                    <Link to='/'><img src="/images/Inner white.png" alt="" /></Link>
                                </div>
                                <p className="footer-text">Litelayer transforms your smartphone with personalized wallpapers and more,</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="footer-icons-box">
                                <h3>Follow us</h3>
                                <ul className="social-icons">
                                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i class="fab fa-x-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row border-line-footer">
                        <div className="col-md-6">
                            <h2 className='copy-right-text'>@ 2024 All rights reserved.</h2>
                        </div>

                        <div className="col-md-6">
                            <ul className='footer-links-copyright'>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms and Conditions</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer