import React, { useRef, useEffect } from 'react';
import './style.css';

const HdImageSwiper = ({ imageUrl, secondImageUrl }) => {
    const sliderRef = useRef(null);
    const resizeRef = useRef(null);
    const dividerRef = useRef(null);

    useEffect(() => {
        if (!sliderRef.current) return;

        const slider = sliderRef.current;
        const resize = resizeRef.current;
        const divider = dividerRef.current;

        // Handle mouse down or touch start on the divider
        const handleDragStart = (e) => {
            e.preventDefault();
            divider.classList.add('draggable');
            resize.classList.add('resizable');

            const startX = e.touches ? e.touches[0].pageX : e.pageX;
            const containerOffset = slider.getBoundingClientRect().left;
            const containerWidth = slider.offsetWidth;
            const dragWidth = divider.offsetWidth;
            const posX = divider.offsetLeft + dragWidth - startX;
            const minLeft = containerOffset + 10;
            const maxLeft = containerOffset + containerWidth - dragWidth - 10;

            const handleDragMove = (moveEvent) => {
                moveEvent.preventDefault();

                const moveX = moveEvent.touches ? moveEvent.touches[0].pageX : moveEvent.pageX;
                let leftValue = moveX + posX - dragWidth;

                if (leftValue < minLeft) {
                    leftValue = minLeft;
                } else if (leftValue > maxLeft) {
                    leftValue = maxLeft;
                }

                const widthValue = ((leftValue + dragWidth / 2 - containerOffset) * 100) / containerWidth + '%';

                divider.style.left = widthValue;
                resize.style.width = widthValue;
            };

            const handleDragEnd = () => {
                divider.classList.remove('draggable');
                resize.classList.remove('resizable');

                document.removeEventListener('mousemove', handleDragMove);
                document.removeEventListener('touchmove', handleDragMove);
                document.removeEventListener('mouseup', handleDragEnd);
                document.removeEventListener('touchend', handleDragEnd);
                document.removeEventListener('touchcancel', handleDragEnd);
            };

            document.addEventListener('mousemove', handleDragMove);
            document.addEventListener('touchmove', handleDragMove);
            document.addEventListener('mouseup', handleDragEnd);
            document.addEventListener('touchend', handleDragEnd);
            document.addEventListener('touchcancel', handleDragEnd);
        };

        divider.addEventListener('mousedown', handleDragStart);
        divider.addEventListener('touchstart', handleDragStart);

        return () => {
            divider.removeEventListener('mousedown', handleDragStart);
            divider.removeEventListener('touchstart', handleDragStart);
        };
    }, [imageUrl, secondImageUrl]);

    return (
        <div className="comparison-slider-wrapper">
            <div className="comparison-slider" ref={sliderRef}>
                <img src={imageUrl} alt="Original" />
                <div className="resize" ref={resizeRef}>
                    {secondImageUrl ? <img src={secondImageUrl} /> : ""}
                </div>
                <div className="divider" ref={dividerRef}></div>
            </div>
        </div>
    );
};

export default HdImageSwiper;
