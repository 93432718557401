import React from 'react'
import './style.css'

const JoinOurCommunity = () => {
    return (
        <>
            <div className="join-our-community-box full-page-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="join-our-community-text">
                                <p>Join our creative community</p>
                                <h1>Trusted by <span>millions+ creators</span> and businesses globally</h1>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                            <div className="number-card">
                                <h1 className='first-color'>3M+</h1>
                                <p>creative edits made every month</p>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                            <div className="number-card">
                                <h1 className='second-color'>1M+</h1>
                                <p>free premium content for everyone</p>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                            <div className="number-card">
                                <h1 className='third-color'>20+</h1>
                                <p>generative AI tools to transform your snap</p>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
                            <div className="number-card">
                                <h1 className='first-color'>30M</h1>
                                <p>editable wallpapers ,images, and more</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JoinOurCommunity