import React from 'react'
import './style.css'
import Slider from "react-slick";


const MobileSlider = () => {

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 500,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: "0px",
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "0px",
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '70px'
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '50px'
                }
            },
            {
                breakpoint: 330,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '0px'
                }
            }
        ]
    };

    return (
        <>

            <div className="dm-width mobile-slider">
                <div className="dm-device">
                    <div className="device">
                        <div className="screen">
                            <div className="slider slider-container">
                                <Slider {...settings}>
                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper1.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper2.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper3.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper4.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper5.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper6.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper7.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper8.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper9.png" alt="" />
                                        </div>
                                    </div>

                                    <div className="slider__item">
                                        <div className='mobile-image'>
                                            <img src="images/phone-slider.png" alt="" />
                                        </div>

                                        <div className='wallpaper-image'>
                                            <img src="images/phonewallpaper10.png" alt="" />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileSlider