import React from 'react'
import './style.css'

const DownloadButtons = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 ps-0">
                        <div className="align-button-box">
                            <div className="download-buttons">
                                <a href="#">
                                    <div className="download-img">
                                        <img src="/images/play-img.webp" alt="" />
                                    </div>

                                    <div className="donwload-text">
                                        <p>Get It On</p>
                                        <h4>Google Play</h4>
                                    </div>
                                </a>
                            </div>

                            <div className="download-buttons">
                                <a href="#">
                                    <div className="download-img">
                                        <i class="fa-brands fa-apple"></i>
                                    </div>

                                    <div className="donwload-text">
                                        <p>Download On The</p>
                                        <h4>App Store</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DownloadButtons