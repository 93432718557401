import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <>
            <header className='header-box'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Link to='/'>
                                <div className="logo-image">
                                    <img src="/images/logo.png" alt="" />
                                </div>
                            </Link>
                        </div>

                        {/* <div className="col-md-6">
                            <div className="grab-app-btn">
                                <a href="#"><span>Grab the app</span></a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header