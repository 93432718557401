import React, { useState } from 'react'
import './style.css'

const CreateRemoveBg = () => {

    // const bgChanges = ['/images/bg1.jpg', '/images/bg2.jpg', '/images/bg3.jpg', '/images/bg4.jpg']

    // const [currIndex, setCurrIndex] = useState(0);

    // const imageUrl = bgChanges[currIndex];

    const [isAnimating, setIsAnimating] = useState(false);

    const changeBackground = (e) => {
        e.preventDefault();
        setIsAnimating(!isAnimating);
        // setCurrIndex((currIndex + 1) % bgChanges.length);
    };

    // const handleDownload = (e) => {
    //     e.preventDefault();
    //     const link = document.createElement('a');
    //     link.href = imageUrl;
    //     link.download = 'downloaded-image.jpg';
    //     link.target = '_blank';
    //     link.rel = 'noopener noreferrer';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };


    return (
        <>
            <div className={`create-bg-image ${isAnimating ? 'anim' : ""} black-box-shadow create-bg-box full-page-section`}>
                <div className="container h-100">
                    <div className={`row h-100 `} >
                        <div className="col-xl-7 col-lg-7 col-md-8 col-sm-12">
                            <div className="create-text-box">
                                <h1>Create, Remove & Change Background Instantly</h1>
                                <p>Creativity with just a click! Craft, erase, and switch backgrounds effortlessly, enhancing your visuals with seamless editing. Your creative journey, simplified at your fingertips!</p>

                                <div className='d-flex'>
                                    <a href="#" onClick={changeBackground}><span>{isAnimating ? "Reverse" : "Apply"} Background</span></a>
                                    {/* <a href="#" onClick={handleDownload} className='download-btn'><span>Download</span></a> */}
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-md-6 pe-sec-control pe-0">
                            <div className={`create-bg-image ${animationClass}`} >
                                <img src={bgChanges[currIndex]} alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div >
        </>
    )
}

export default CreateRemoveBg