import React, { useState } from 'react';
import MyContext from './contaxt';

const MyProvider = ({ children }) => {

    // CHANGE BACKGROUND TABS
    const bgChanges = ['/images/bg1.jpg', '/images/bg2.jpg', '/images/bg3.jpg', '/images/bg4.jpg', '/images/bg5.jpg']
    const [currIndex, setCurrIndex] = useState(0);

    const changeBackground = (e) => {
        e.preventDefault();
        setCurrIndex((e) => (e + 1) % bgChanges.length)
    }
    // CHANGE BACKGROUND TABS

    // REMOVE BACKGROUND TABS
    const bgRemove = ['/images/removebgone.jpg', '/images/removebg-two.png']
    const [currIndexBg, setCurrIndexBg] = useState(0);

    const RemoveBgTabsFunc = (e) => {
        e.preventDefault();
        setCurrIndexBg((r) => (r + 1) % bgRemove.length);
    };

    // REMOVE BACKGROUND TABS

    const allStates = {
        currIndex: currIndex,
        bgChanges: bgChanges,
        changeBackground: changeBackground,
        currIndexBg: currIndexBg,
        RemoveBgTabsFunc: RemoveBgTabsFunc,
        bgRemove: bgRemove
    }

    return (
        <MyContext.Provider value={allStates}>
            {children}
        </MyContext.Provider>
    );
};

export default MyProvider;
