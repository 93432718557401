import React from 'react'
import './style.css'
import DownloadButtons from '../DownloadButtons/DownloadButtons'

const Banner = () => {
    return (
        <>

            <div className="mian-banner-box">
                <div className="container h-100">
                    <div className="row h-100 reverse-box-column">
                        <div className="col-md-6">
                            <div className='banner-text-box'>
                                <h1>Revolutionize Your Wallpapers: <br />
                                    Step into <span>Litelayers</span></h1>
                                <p>"Elevate your imagery game with Litelayers: Power-packed editing at your fingertips!"</p>
                            </div>

                            <div className="content-left-align">
                                <DownloadButtons />
                            </div>
                        </div>

                        <div className="col-md-6 position-relative">
                            <div className='phone-banner-image'>
                                <img src="/images/phone-banner.png" alt="" />
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-md-6">
                                <div className='phone-banner-image'>
                                    <img src="/images/phone-banner.png" alt="" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>




            {/* <div className="banner-box">
                <img src="/images/Hero-section-Image.png" alt="Banner Image" />
                <div className='phone-banner-image'>
                    <img src="/images/phone-banner.png" alt="" />
                </div>
            </div > */}
        </>
    )
}

export default Banner