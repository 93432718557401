import React from 'react'
import './style.css'
import DownloadButtons from '../DownloadButtons/DownloadButtons'

const UltimateCompain = () => {
    return (
        <>
            <div className="ultimate-compain-box full-page-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ultimate-image">
                                <img src="/images/lite-layer.png" alt="" />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="play-store-buttons">
                                <div className="ultimate-text-box">
                                    <h1>Ultimate <span>Wallpaper Companion!</span></h1>
                                    <p>Transform your device's look with Litelayer, the go-to app for stunning wallpapers. Explore endless options and customize your screen with ease</p>
                                </div>
                                <DownloadButtons />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UltimateCompain